<template lang="pug">
  tr(key="total-row" class="ds-row-total")
    td.ds-id
      span.text-bold Total
    td.ds-name
      span &nbsp;
    td.ds-slug
      span &nbsp;
    td.ds-number
      span &nbsp;
    td.ds-candidates-count
      span {{ totals.total_count }}
    td.ds-amount-calc
      span €{{ totals.total_amount }}
    td.ds-count-unpaid
      span {{ totals.total_count_unpaid }}
    td.ds-amount-unpaid
      span €{{ totals.total_amount_unpaid }}
    td.ds-actions
      span &nbsp;
</template>

<script>
export default {
  props: {
    totals: Object
  }
}
</script>

<style lang="scss" scoped>
.ds-row-total {
  background-color: #f1f2f6 !important;

  td {
    border-top: 1px solid #aeaeae;
  }
}
</style>
